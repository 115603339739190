.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    padding: 10px;
    max-width: 960px;
    align-items: center;
    margin: 0px auto;
    transition: 1s;


  }
  .sixElementsGridContainer{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 40px;
    padding: 20px;
    max-width: 920px;
    align-items: center;
    margin: 30px auto 0px auto ;
    transition: 1s;
  }
  
  .gridContainer > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 30px;
    transition: 1s;

  }
  
@media only screen and (max-width: 960px) {
    .gridContainer{
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        padding: 10px;
        transition: 1s;

    }
    .sixElementsGridContainer{
        display: grid;
        grid-template-columns: auto auto auto ;
        gap: 40px;
        padding: 10px;
         transition: 1s;
        
    }
  }

    
@media only screen and (max-width: 500px) {
    .gridContainer{
        display: grid;
        grid-template-columns: auto;
        margin: 0px auto;
        padding: 20px;
    }
    .sixElementsGridContainer{
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;
      padding: 10px;
  }
  }