#horizontalViewContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 100%;
}
@media only screen and (max-width: 960px) {
    #horizontalViewContainer{
      flex-direction: column;
      margin: 0rem;
    }
  }
#verticalViewContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 100%;
}
