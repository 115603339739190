footer {
  margin-top: auto;
  bottom: 0;
  right: 0;
  left: 0;
}

.footerStrip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
}

#topLine {
  margin-top: 20px;
  height: 2px;
  background-color: var(--blue3);
  width: 100%;
}

#primary {
  margin-top: 20px;
  background-color: var(--blue4);
  font-weight: 600;
  font-size: 15px;
}

.footerStrip li {
  list-style: none;
  position: relative;
  padding: 10px 0;
  margin: 0 5px;
  transition: 0.3s ease-in-out;
}

li:after,
li:before {
  position: absolute;
  bottom: 0px;
  width: 7px;
  height: 7px;
  content: " ";
  transition: 0.3s ease-in-out;
}
li:hover::before,
li.active::before {
  border-bottom-right-radius: 10px;
  border-width: 0 1px 1px 0;
  left: -7px;
}

li:hover::after,
li.active::after {
  border-bottom-left-radius: 10px;
  border-width: 0 0 1px 1px;
  right: -7px;
}

#primary li:hover::before,
#primary li.active::before {
  box-shadow: 2px 2px 0 var(--blue5);
}

#primary li:hover::after,
#primary li.active::after {
  box-shadow: -2px 2px 0 var(--blue5);
}

#secondary li:hover::before {
  box-shadow: 2px 2px 0 var(--blue6);
}

#secondary li:hover::after {
  box-shadow: -2px 2px 0 var(--blue6);
}

.footerStrip li a {
  color: white;
  padding: 10px 10px;
  transition: 0.3s ease-in-out;
}

#primary li a:hover,
#primary li a.active {
  text-decoration: unset;
  background-color: var(--blue5);
}

/* secondary */

#secondary {
  margin: 0rem;
  background-color: var(--blue5);
  font-weight: 400;
  font-size: large;
  min-height: 0.2rem;
  transition: 0.2s;
}

#secondary li a:hover {
  background-color: var(--blue6);
  text-decoration: none;
}

#socialMedia {
  margin: 0rem;
  background-color: var(--blue6);
  font-weight: 400;
}
.iconSocialMedia {
  background-color: var(--blue4);
  height: 20px;
  border-radius: 1.5rem;
  margin: 0.5rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  transition: 0.3s;
}
.iconSocialMedia img {
  height: 1.2rem;
}
.iconSocialMedia:hover {
  /* margin: .3rem .5rem; */
  margin: 0.1rem 0.35rem;
  border: var(--blue4) solid 1px;
  box-shadow: 0px 20px 20px var(--blue7);

  background-color: var(--blue3);
}

/* copyrights */
#copyrights {
  background-color: var(--blue7);
  text-decoration: none;
  font-weight: 500;
  color: white;
  padding: 0.3rem 0;
}

@media only screen and (max-width: 960px) {
  #copyrights {
    background-color: var(--blue3);
    margin-top: 20px;
    height: 40px;
  }
}

/* =============================================== */
/* Lines & footer Items =================*/

.footerItem div {
  height: 2px;
  position: 0;
  margin-top: 1px;
}

.footerItem:hover div,
.footerItem.active div {
  opacity: 1;
  position: 0;
  /* transform: scaleX(1); */
}

.language-select {
  padding: 3px;
  font-size: 14px;
  border-radius: 7px;
  color: white;
  transition: 1s;
  cursor: pointer;
  overflow: hidden;
}

.language-option {
  height: 20px;
  width: 20px;
  border-radius: 7px;
  padding: 2px;
  background-color: transparent;
}

.language-option:hover {
  background-color: var(--blue3);
}
