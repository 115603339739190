
.login-form {
  margin: 0 auto;
  border-radius: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: white;
}
.loggedInContainer {
  margin: 0 auto;
  border-radius: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: white;
  padding: 50px;
}


.titleContainer {
  background-color: var(--blue6);
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.title {
  color: white;
  text-align: center;
  margin: 10px;
}

.cspTitleContainer {
  margin-top: 20px;
  background-color: var(--blue6);
  border-radius: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.formRow {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin: 20px 0px;
  width: 280px;
  border-radius: 30px;
  justify-content: center;
  border: 1px solid var(--blue1);
  align-items: center;
  cursor:text;
  gap: 10px;
}

.formFieldText {
  font-size: 15px;
  font-weight: 600;
  color: var(--blue2);
}

.formButtonsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 30px;
  align-items: center;
  cursor:text;
  gap: 10px;
}

.formLabel {
  font-size: 15px;
  font-weight: 600;
  color: var(--blue2);
  width: 22%;
  position: absolute; 
  transform: translate(-82%, 0);
  cursor:text;
  z-index: 2;
}

.formInput {
  width: 100%;
  height: 100%;
  border: 0px solid var(--blue1);
  text-align: center;
  font-weight: 600;
  color: var(--blue4);
  transition: .25s ease-in-out;
  border-radius: 2px;
  z-index: 1;
}
.formInput:focus {
  border-radius: 20px;
  width: 75%;
  border: 1px solid var(--blue1);
  transition: .25s ease-in-out;
  transform: translateX(15%);

}

input {
  transition: ease-in-out, width 1s ease-in-out;
}

.outButton {
  margin-top: 20px;
  padding: 7px;
  border-radius: 30px;
  width: 300px;
  background-color: var(--blue3);
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  border: 0px solid var(--blue1);
  transition: .25s ease-in-out;
}
.outButton:hover {
  background-color: var(--blue1);
  transition: .25s ease-in-out;
}
.submitButton {
  margin: 0px;
  padding: 7px;
  border-radius: 30px;
  width: 100%;
  background-color: var(--blue3);
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  border: 0px solid var(--blue1);
  transition: .25s ease-in-out;
}
.submitButton:hover {
  background-color: var(--blue1);
  transition: .25s ease-in-out;
}
.backgroundBlur {
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: var(--blue3);
  overflow: hidden;
  overflow-y: scroll;
  position: fixed; 
  height: 100vh;
  width: 100vw;
  background-color: var(--blue5);
  transition: .5s;
  z-index: 97;
  flex-direction: column;
  display: flex;
  background:rgba(0, 15, 152, 0.5); 
  backdrop-filter: blur(5px);
}

.close {
  height: 24px;
  width: 24px;
  transition: 300ms;
  margin: 7px;
  position: absolute;
  top: 10px;
  right: 5px;
}
.close:hover {
  margin: 0px;
  height: 30px;
  width: 30px;
  padding: 4px;
  background-color: #1886e08f;
  border-radius: 15px;
  cursor: pointer;
}


@media (max-width: 500px) {
  .fields {
    transition: 0.5s;
    margin: 10px 20px  10px 20px;
  }
  .formRow {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    margin: 10px 0px;
    width: 100%;
    border-radius: 30px;
    justify-content: center;
    border: 1px solid var(--blue1);
    align-items: center;
    cursor:text;
  }
  .shareContainer {
    padding: 10px;
  }
  .shareButton {
    width: 100%;
  }
  .login-form{
    width: 100%;
  }
}
