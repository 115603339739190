
.login-form {
  margin: 0 auto;
  border-radius: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: white;
}

.shareContainer {
  margin: 0 auto;
  border-radius: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: white;
  padding: 50px;
}


.fields {
  margin: 10px 90px  40px 90px;
  transition: 0.5s;
}


.titleContainer {
  background-color: var(--blue6);
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  align-items: center;
  justify-content: center;  
}
.title {
  color: white;
  text-align: center;
  margin: 10px;
}

.formRow {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin: 20px 0px;
  width: 280px;
  border-radius: 30px;
  justify-content: center;
  border: 1px solid var(--blue1);
  align-items: center;
  cursor:text;
}
.errorTextRow {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin: 10px 0px;
  width: 280px;
  font-size: 18;
  font-weight: 600;
  color: var(--red1);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  cursor:text;
}

.formLabel {
  font-size: 15px;
  font-weight: 600;
  color: var(--blue2);
  width: 22%;
  position: absolute; 
  transform: translate(-82%, 0);
  cursor:text;
  z-index: 2;

}

.formInput {
  width: 100%;
  height: 100%;
  border: 0px solid var(--blue1);
  text-align: center;
  font-weight: 600;
  color: var(--blue4);
  transition: .25s ease-in-out;
  border-radius: 2px;
  z-index: 1;
}
.formInput:focus {
  border-radius: 20px;
  width: 75%;
  border: 1px solid var(--blue1);
  transition: .25s ease-in-out;
  transform: translateX(15%);

}

input {
  transition: ease-in-out, width 1s ease-in-out;
}

.linkLabel {
  margin: 20px;
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid var(--blue1);
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 5px 10px; */
  width: 300px;
  border-radius: 30px;
  align-items: center;
  cursor:text;
  
}

.shareButton {
  font-size: 16px;
  min-width: 180px;
  padding: 7px;
  margin: 5px;
  border-radius: 30px;
  background-color: var(--blue3);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border: 0px solid var(--blue1);
  transition: .25s ease-in-out;
}

.shareButton:hover {
  background-color: var(--blue1);
  transition: .25s ease-in-out;

}
.buttonDisabled, .buttonDisabled:hover{
  background-color: #586c86;
  cursor: auto;
}

.closeButton {
  margin-top: 20px;
  font-size: 14px;
}

.closeButton:hover {
 
  background-color: var(--red1);
  transition: .25s ease-in-out;

}

.addGuest {
  height: 32px;
  width: 32px;
  transition: 300ms;
  margin: 8px;
}
.addGuest:hover {
  margin: 0px;
  height: 40px;
  width: 40px;
  padding: 4px;
  background-color: #deebff;
  border-radius: 10px;
  cursor: pointer;
}

button {
  padding: 7px 20px;
  margin: 0px;
  border-radius: 30px;
  /* width: 140px; */
  background-color: var(--blue3);
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  border: 0px solid var(--blue1);
  transition: .25s ease-in-out;
}

button:hover {
  background-color: var(--blue1);
  transition: .25s ease-in-out;
}

.backgroundBlur {
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: var(--blue3);
  overflow: hidden;
  overflow-y: scroll;
  position: fixed; 
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blue5);
  transition: .5s;
  z-index: 97;
  flex-direction: column;
  display: flex;
  background:rgba(0, 15, 152, 0.5); 
  backdrop-filter: blur(5px);
}

@media (max-width: 500px) {
  .fields {
    transition: 0.5s;
    margin: 10px 20px  20px 20px;
  }
  .formRow {
    display: flex;
    flex-direction: row;
    padding: 5px 5px;
    margin: 10px 0px;
    width: 90vw;
    border-radius: 30px;
    justify-content: center;
    border: 1px solid var(--blue1);
    align-items: center;
    cursor:text;
  }
  .shareContainer {
    padding: 10px;
  }
  .shareButton {
    width: 100%;
  }
  .formLabel {
    width: 30%;
  }
  .login-form{
    width: 100%;
  }
}
