#lineSplitter{
    margin: 15px 0;
    /* margin-bottom: .8rem; */
    width: 100%;
    height: 1px;
}
#verticalLineSplitter{
    margin: 0px 10px;
    width: 1px;
    min-height: 8rem;
    display: block;
    height: 100%;
    
}

@media only screen and (max-width: 960px) {
    #verticalLineSplitter {
        margin: 15px 0;
        width: 100%;
        height: 1px;
        min-height: 1px;
    }
  }
  

.phoneNumbersMenuIcon{
    height: 1.2rem;
    border-radius: 1.5rem;
    margin: .5rem;
    padding: .5rem;
    justify-content: center;
    align-items: center;
    /* display: flex; */
    transition: .3s;
    background:rgba(50, 101, 255, 0.8); 
    margin: .1rem .35rem;
    border: var(--blue4) solid 1px;
    box-shadow: 0px 20px 20px var(--blue7);
    transform: translate(0px, 8px);
    cursor:pointer;
}

/* .phoneNumbersMenuIcon::after{
    position: absolute;
    bottom: 0px;
    width: 10px;
    height: 10px;
    content: " ";
    transition: 0.3s ease-in-out;

    border-top-right-radius: 10px;
    border-width: 0 0 1px 1px;

    left: -7px;
    transform: translate(-5px, -24px);
    box-shadow: 2px -2px 0 var(--blue4);

}

.phoneNumbersMenuIcon::before{
    position: absolute;
    bottom: 0px;
    width: 10px;
    height: 10px;
    content: " ";
    transition: 0.3s ease-in-out;

    border-top-left-radius: 10px;
    border-width: 0 0 1px 1px;

    left: -7px;
    transform: translate(45px, -24px);
    box-shadow: -2px -2px 0 var(--blue4);

}
 */



.phoneNumbersMenuIcon img{
    height: 1.2rem;

}
.phoneNumbersMenu{
    position: absolute;
    /* background-color: var(--blue4);  */
    background-image: url('../../res/images/Group.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* background:var(--blue4);  */
    /* background:rgba(50, 101, 255, 0.8);  */
    /* border-radius: 15px; */
    /* border: solid .5px var(--blue4); */
    /* box-shadow: 0px 20px 20px var(--blue7); */
    height: 160px;
    width: 190px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(77px, -32px);
    padding: 5px 10px;
    transition: .5s;
}
.phoneNumbersMenu a{
    backdrop-filter: blur(3px);
    padding: 1px 3px;
    text-decoration: none;
    width: 98%;
    text-align: center;
    transition: .3s;
    border-radius:5px;
    font-weight: 500;
    color: white;

}
@media only screen and (max-width: 960px) {
    .phoneNumbersMenu {
        /* background:rgba(50, 101, 255, 0.8);  */
    /* border-radius: 15px; */
    /* border: solid .5px var(--blue4); */
        transform: translate(74px, -125px);
    }
  }
  

.phoneNumbersMenuContent{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.phoneNumbersMenu a:hover{
    background:rgba(24, 66, 194, 0.822);
}



.float{
	position:fixed;
	width:38px;
	/* height:160px; */
	bottom:50vh;
	right:0px;
	/* background-color:#f3f3f3;
     */
    background:white;
    background:rgba(207, 207, 207, 0.8);
    backdrop-filter: blur(6px);
    background-blend-mode: overlay;
	/* color:black; */
	border-radius:25px 0 0 25px;
	text-align:center;
    padding: 5px;
    justify-content: space-between;
	/* box-shadow: 2px 2px 3px #888; */

}

.floatIcon{
    padding: 10px 0;
    font-size: 10px;
    font-weight: 500;
    flex-direction: column;
}
.floatIcon:hover{
    filter: brightness(.5);
}
.floatIcon a{
    text-decoration: none;
}



/* roundedButton */
.roundedButton{
        align-items: center;
        justify-content: center;
        display: flex;
        
        max-width: 960px;
        width: 92vw;
        /* margin: 2rem auto; */
        border-radius: 1rem;
        border-width: 0;
        padding: .2rem .2rem;

        background-color: var(--blue4);
        color: white;
        font-size: xx-large;
        font-family: 'Barlow Condensed', sans-serif;
        font-weight: 600;
        text-decoration: unset;

        transition: .2s;
}
.roundedButton:hover{
    /* background-color: var(--blue5); */
    filter: brightness(1.3);
}

.outlinedButton{
    align-items: center;
    justify-content: center;
    display: flex;
    
    max-width: 960px;
    width: 92vw;
    /* margin: 2rem auto; */
    border-radius: 1rem;
    border-width: 0;
    padding: .2rem .2rem;

    background-color: "transparent";
    border: 1px solid var(--blue3);
    color: white;
    font-size: xx-large;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    text-decoration: unset;
    backdrop-filter: blur(6px);


    transition: .2s;
}


/* Box*/
.box{
    align-items: center;
    justify-content: center;
    display: flex;
    
    max-width: 960px;
    width: 92vw;
    margin: 1rem auto;
    border-width: 0;
    
    color: white;
}

@media only screen and (max-width: 960px) {
  .box {
    flex-direction: column;
  }
}

.roundedBox{
    align-items: center;
    justify-content: center;
    display: flex;
    max-width: 960px;
    width: 92vw;
    /* margin: 2rem auto; */
    border-radius: 1rem;
    border-width: 0;
    background-color: var(--blue3);

    
    color: white;
    border-radius: 1rem;
}


/* toggleItem */

.toggleItem{
    
    display: flex;
    flex-direction: column;
    transition: .3s;
}
.toggleItem div{
    cursor: pointer;
    transition: .3s;
}
.expandIcon{
    transition: .5s;
}
.expanded .expandIcon{
    transform: rotate(90deg);
}
.toggleItem div:hover{
    color: var(--blue2);
}
.expanded div{
    
    padding-bottom: .2rem;
    color: var(--blue5);
}
.expanded{
    padding-bottom: 1rem;
}

/* Spinner */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
  }