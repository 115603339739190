#waveViewContainer{
    background-color: var(--blue4);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0rem;
    transition: .5s;
    
}
#waveTextContainer{
    padding: 0em 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60em;
    margin: 0 10%;
}

#waveViewContainer:hover {
    background-color: var(--blue5);
    padding: 12px 0;
    margin: 12px 0rem;
}
#waveViewContainer:hover #waveViewLineUp{
    transform: translate(0px, -19px);
}
#waveViewContainer:hover #waveViewLineDown{
    transform: translate(0px, 19px);
}


p{
    text-align:center;
}

/* ================================================== */
/* Waves Lines */
.waveViewLine{
    display: flex;
    justify-content: center;
    align-items: center;
}

.waveViewLine img{
    object-fit: cover;
    width: 100%; 
    height: 16px;
}


#waveViewLineUp{
    transform: translate(0px, -7px);
    transition: .5s;
}
#waveViewLineDown{
    transform: translate(0px, 7px);
    transition: .5s;
}