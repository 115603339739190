:root {
  --blue1: #32ccff;
  --blue2: #32AAFF;
  --blue3: #3287FF;
  --blue4: #3265FF;
  --blue5: #5500FF;
  --blue6: #3A00C0;
  --blue7: #000F98;
  --red1: #FF3232;
  --screenWidth: 960px;
  font-family: 'Barlow Condensed', sans-serif;
  
};


* {
  font-family: 'Barlow Condensed', sans-serif;
}

#root{
  
  font-family: 'Barlow Condensed', sans-serif;
  margin: 0;
  color: var(--blue3);
  min-height: 100vh;
  
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


hr{
  margin-top: .7rem;
}

h1{
  font-weight: 600;
  font-size: 36px;
  margin: 4px 40px;
}
h2{
  font-weight: 500;
  font-size: x-large;
  margin: .2rem 1rem;
}
h3{
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
}
h4{
  font-weight: 500;
  font-size: medium;
  margin: .1rem 1rem;
}
h5{
  font-weight: 500;
  font-size: small;
  margin: 8px 10px;
}
p{
  font-weight: 500;
  font-size: 16px;
  margin: 10px 20px;
}
text li{
  font-weight: 500;
  font-size: large;
  margin: .2rem 1rem;
}
h2 + h3 {
  padding-left: 1.3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
iframe{
  filter: invert() hue-rotate(200deg) saturate(2);
}

#locations_div{
  overflow:hidden;
      clip-path: polygon(0 9.5%, 100% 9.5%, 100% 97%, 0% 97%);
  }