.cyanBackground {
  background-color: #d8eff8;
}
.fadedRow{
  opacity: .6;
}

tr{
  padding: 40px;
}

.tableStyle{
  background-color: #f4fbfd;
  margin: 20px;
  border-radius: 8px;

}

@media (min-width: 768px) {
  th {
    color: white;
    padding: 5px 10px;
    margin: 20px;
    border-radius: 20px;
  }
}

td {
  text-align: center;
  color: var(--blue4);
  font-weight: 500;
  font-size: larger;
  padding: 2px 3px;
  border-radius: 5px;
}


@media (max-width: 767px) {
  .guestsTable {
    overflow-x: auto;
    width: 100%;
  }
  th, td {
    display: block;
  }
  th {
    border: none;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: white;
    padding: 2px 10px;
    /* color: var(--blue7); */
  }
  tr{
  }
  td {
    border: none;
    border-bottom: 2px solid #9694ff;
    color: var(--blue4);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 3px;
    border-radius:  0px;
  }
  td:before {
    width: 100%;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 60%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: bold;
  }
 
}

.color1 {
  background-color: var(--blue1);
}
.color2 {
  background-color: var(--blue2);
}
.color3 {
  background-color: var(--blue3);
}
.color4 {
  background-color: var(--blue4);
}
.color5 {
  background-color: var(--blue5);
}
.color6 {
  background-color: var(--blue6);
}
.color7 {
  background-color: var(--blue7);
}
.color8 {
  background-color: var(--blue7);
}

/* The style to make the header cells clickable */
.sortable {
  cursor: pointer;
}

/* Add this style for the ascending and descending indicators */
.asc::before {
  content: '\25B2'; /* Up arrow */
  margin-left: 5px;
}

.desc::before {
  content: '\25BC'; /* Down arrow */
  margin-left: 5px;
}

.container {
  justify-content: center;
  align-self: center;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;

}
.guestsTable {
  overflow-x: auto;
  justify-content: flex-start;
  align-self: flex-start;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: scroll;
  
}

.guestDetailsContainer {
  align-items: center;
  align-content: center;
}

.guestDetailsTable {
  width: 80%;
  background-color: var(--blue7);
  border-radius: 10px;
  padding: 10px;
  margin: 30px 10%;
  align-items: center;
  align-content: center;
  justify-self: center;
  align-self: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.logout {
  height: 24px;
  width: 24px;
  transition: 300ms;
  margin: 7px;
}
.logout:hover {
  margin: 0px;
  height: 30px;
  width: 30px;
  padding: 4px;
  background-color: #deebff;
  border-radius: 10px;
  cursor: pointer;
}
.share {
  height: 18px;
  width: 18px;
  transition: 300ms;
  margin: 7px;
}
.share:hover {
  margin: 0px;
  height: 24px;
  width: 24px;
  padding: 4px;
  background-color: #deebff;
  border-radius: 10px;
  cursor: pointer;
}

.logoImage {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 3px double var(--blue2);
  margin-left: 15px;
}
.paidButton {
  padding: 0px 10px;
  margin: 0px;
  border-radius: 30px;
  background-color: transparent;
  color: var(--blue4);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--blue3);
  transition: .25s ease-in-out;
  align-self: center;
}

.paidButton:hover {
  transition: .25s ease-in-out;
  color: rgb(250, 133, 0);
}
.paidButtonDisabled {
  border: 1px solid rgb(66, 104, 55);
  color: rgb(66, 104, 55);
}
.paidButtonDisabled:hover {
  background-color: white;
  transition: .25s ease-in-out;
}
.paidButtonEnabled {
  border: 1px solid rgb(250, 133, 0);
  color: rgb(250, 133, 0);
}

.popup {
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid var(--blue2);
  border-radius: 20px;
  padding: 10px;
  z-index: 999;
}

.row {
  flex-direction: row;
}
p {
  margin: 0;
}

.payStatusIcon {
  border: 1px solid var(--blue2);
  border-radius: 20px;
}



.payButton {
  margin-top: 20px;
  padding: 7px 16px;
  margin-bottom: 5px;
  border-radius: 20px;
  background-color: transparent;
  color: var(--blue4);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--blue3);
  transition: .25s ease-in-out;
}

.okButton {
  padding: 5px 10px;
  font-size: 15px;
}


.bulkActions {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.bulkActions select {
  padding: 6px;
  font-size: 14px;
  border: 1px solid var(--blue3);
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.bulkActions button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: var(--blue3);
  color: 'white';
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bulkActions button:hover {
  background-color: var(--blue4);;
}