#ImageCoverContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center  ;
    padding: 20px;
}

#ImageCover{
    width: 100vw;
    height: 240px;
    object-fit: cover;
    margin: .5rem;
    border-radius: 1.3rem;

    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: auto;
    
}

.imageMedia{
    min-width: 200px;
    width: 100%;
    height: 225px;
    object-fit: cover;
    margin: 15px;
    border-radius: 1.3rem;

    display: flex;
    flex-direction: column;
    margin: auto;
}


.imageCircle{
    min-width: 50px;
    min-width: 50px;
    object-fit: cover;
    border-radius: 100%;

    display: flex;
    flex-direction: column;
    margin: auto;
    
}