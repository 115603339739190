#borderViewContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center  ;

    padding: 20px;

    max-width: 960px;
    margin: 0px auto;
}



.borderViewLine{
    border: 1px solid ;
    margin: 1px;
}


#borderViewLine1{
    width: 100%;
    border-radius: 20px;
    border-color: var(--blue3);
}
#borderViewLine2{
    border-radius: 18px;
    border-color: var(--blue2);
}
#borderViewLine3{
    border-radius: 16px;
    padding: 10px;
    border-color: var(--blue1);
    

 
}
#borderTextContainer{
    width: 100%;

}