
nav{
    display: flex;
    align-items: center;
    justify-content: center;
    
}


/* navbar */
#navbar {
    width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0rem;
    padding: 0 0 10px 0;
}

#navbar li {
    list-style: none;
    /* padding: 0 12px; */
    position: relative;
    
}
#navbar li a{
    text-decoration: none;
}

.navbarItemText {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--blue2);
    transition: 0.3s ease-in-out;
}

 #navbar li a:hover a,
#navbar li a.active
 {
    color: var(--blue4);
}





/* =============================================== */
/* Lines & Navbar Items =================*/

/* 17px padding, and dot with 4px */


.navbarItem div{
    height: 2px;
    position: 0;
    margin-top: 1px;
}

.navbarItem:hover div,
.navbarItem.active div
{
    opacity: 1;
    position: 0;
}

.navbarItem .line1{
    opacity: 0;
    background: var(--blue3);
    transform: translate(0px, 2px);
    transition: 0.2s ease-in-out;
}
.navbarItem:hover .line1,
.navbarItem.active .line1{
    opacity: 1;
    transform: translate(0px, 2px);
}

.navbarItem .line2{
    background: var(--blue2);
    transform: translate(0px, 8px);
    transition: 0.25s ease-in-out;

}
.navbarItem:hover .line2,
.navbarItem.active .line2
{
    transform: translate(0px, 5px);
}
.navbarItem .line3{
    background: var(--blue1);
    transform: translate(0px, 4px);
    transition: 0.3s ease-in-out;
}
.navbarItem:hover .line3,
.navbarItem.active .line3
{
    transform: translate(0px, -1px);
}









/* =============================================== */
/* Logo & Top Buttons =============================*/

#topAreaContainer{

    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: auto;
}
.buttonsRow{
    position: absolute;
    
    width: 960px;
    display: flex;
    align-items: top;
    justify-content: space-between;
}


.logoRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0px;
    transition: .5s;
}
.logo{
    max-width: 50%;
    transition: .5s;
}
.goToDashboardRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0.5rem 0;
    transition: .5s;
}

.ctaButtonContainer{
    min-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ctaButton{

    font-family: 'Barlow Condensed', sans-serif;
    font-size: medium;
    font-weight: 500;
    color: white;
    text-decoration: none;
    border: 0;
    min-width: 150px;
    border-radius: 0% 0% 20px 20px;
    text-align: center;
    padding: 12px 20px;
    transition: .5s ease-out;
}
   
@media only screen and (max-width: 500px) {
    .ctaButtonContainer{
        min-width: 50px;
    }
    .ctaButton{
        min-width: 50px;
    }
    .logo img{
        width: 100%;
        min-width: 100px;
    }
    .menuButton{
        margin: 20px;
        transition: .5s;
    }
    .logo{
        margin: 0 0 0 40px;
        transition: .5s;

    }

  }
#loginButton{
    background-color: var(--blue2);
}
#hotlineButton{
    background-color: var(--red1);
}
.ctaButton:hover{
    opacity: .8;
    padding:12px 20px;
}
#hotlineButton{
    background-color: var(--red1);
}


@media only screen and (max-width: 960px) {
    .buttonsRow{
        position: relative;
        width: 100vw;
        margin: 0rem;
    }
    .ctaButton{
        width: 100vw;
        margin-bottom: 20px;
        border-radius: 0% 0% 0px 0px;
    }
    .logoRow {
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
    }
}


.subMenuHidden{
    height: 0px;
    opacity: 0;
    width: 960px;
    transition: 2s;
    position: absolute;
    display: flex;
    justify-content: space-between;
    transform: translate(-93px, 0px) scaleX(0);
    /* transform: translate(-70px, 0px) scaleX(0); */
    
}

.subMenu{
    transition: .4s;
    position: absolute;
    width: 960px;
    display: flex;
    justify-content: space-between;
    transform: translate(-93px, 0px);

    z-index:99;
}


.subMenuItem{
    /* padding: 2px 8px; */
    font-size: 15px;
    font-weight: 600;
    color: var(--blue1);
    transition: .3s;
}


.subMenuItem:hover{
    color: var(--blue3);
}


.menuButton{
    height: 60px;
    width: 60px;
    background-color: var(--blue5);
    border-radius: 100%;
    margin: 20px 20px 20px 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .5s;
    z-index: 91;


}
.menuButton> div{
    height: 4px;
    width: 30px;
    background-color: white;
    margin: 4px;
}

.menuButtonPressed{
    background-color: white;
    transform: rotate(90deg);
    transition: .5s;
}

.menuButtonPressed> div{
    height: 4px;
    width: 30px;
    background-color: var(--blue5);
    margin: 4px;
}


.mobileMenuContainer{
    overflow: hidden;
    overflow-y: scroll;
    position: fixed; 
    height: 100vh;
    width: 100vw;
    background-color: var(--blue5);
    transition: .5s;
    z-index: 90;
    padding: 90px;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    display: flex;
    background:rgba(0, 15, 152, 0.8); 
    backdrop-filter: blur(5px);
}

.mobileMenuContainerClosed{
    position: absolute;
    transition: .5s;
    transform: translateX(-100vw);
    opacity: 0;
    height: 100vh;
    z-index: 90;
}

.itemMobileMenu{
    color: white;
    text-decoration: none;
    font-size: 22px;
    font-weight: 500;
    padding: 10px;
}

.subItemMobileMenu{
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 10px 10px 30px ;
}